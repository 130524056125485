<template>
  <div>
    <div>排考方式：{{ formList.arrangeModel | arrangeModelFilters }}</div>
    <div>考试开始： {{ formList.aheadMinute }} 分钟后可提前交卷</div>
    <div v-if="formList.arrangeModel == 1">
      考试开始前： {{ formList.aheadDrawTime }} 分钟，可提前抽签
    </div>
    <template v-if="formList.arrangeModel != 2">
      <div>
        答题时长： {{ formList.examAnswerTime }} 分钟，考试间隔
        {{ formList.examInterval }} 分钟
      </div>
      <div>
        排考算法: {{ formList.arrangeAlgorithm | arrangeAlgorithmFilters }}
      </div>
      <div>抽签模式: {{ formList.drawModel | drawModelFilters }}</div>
      <div>
        每日考试时间：
        <span
          v-for="(val, i) in formList.examArrangementTimeSegmentList"
          :key="i"
          style="margin: 0 10px"
          >{{ (val.examStartTimeDiff + zeroTime) | timeFilters }}~{{
            (val.examEndTimeDiff + zeroTime) | timeFilters
          }}</span
        >
        <span
          v-for="(val, i) in formList.examTimeSegmentList"
          :key="i"
          style="margin: 0 10px"
          >{{ (val.examStartTimeDiff + zeroTime) | timeFilters }}~{{
            (val.examEndTimeDiff + zeroTime) | timeFilters
          }}</span
        >
      </div>
    </template>
    <template v-if="formList.arrangeModel == 2">
      <div>
        抽签方式：
        {{ formList.randomTimingDrawType | randomTimingDrawTypeFilters }}
      </div>
      <div>
        报到模式：{{
          formList.randomTimingType | randomTimingTypeFilters
        }}
      </div>
    </template>

    <template v-if="formList.fixTime && formList.arrangeModel != 1">
      <div>
        定时时长：{{ formList.fixTimeLength }}分钟，可视情况提前结束考试/继续考试
      </div>
      <div>
        定时提醒：{{
          formList.fixTimeRemindLength
        }}秒后，可选择继续考试/自动结束考试
      </div>
    </template>

    <!-- <div>
        每日考试时间：
        <span v-for="(val,i) in listTime" :key="i">
          <span v-if="i">,</span>
          {{val | timeFilters}}-{{(val+(formList.examAnswerTime * 60000))|timeFilters}}
        </span>
      </div>-->
  </div>
</template>

<script>
import { inquireRule, inquireRule2 } from '@/api/exam/exam.js'
import dayjs from 'dayjs'
export default {
  props: ['draftStatus'],
  data() {
    return {
      examId: '',
      formList: {},
      zeroTime: 0,
      listTime: [],
    }
  },
  async mounted() {
    this.examId = this.$route.query.examId
    if (this.draftStatus >= 4) {
      await this.defaultReq2()
    } else {
      await this.defaultReq()
    }
    if (this.formList.arrangeModel == 2) {
      this.$store.commit('set_duration', 10)
    } else {
      this.$store.commit('set_duration', this.formList.examAnswerTime)
    }
  },
  methods: {
    async defaultReq() {
      this.zeroTime = new Date(new Date().toLocaleDateString()).getTime()
      await inquireRule(this.examId).then((res) => {
        console.log('考试规则', res)
        if (res.success) {
          this.formList = res.data
          console.log('this.formList ', this.formList)
          // this.changTimeList()
        }
      })
    },
    async defaultReq2() {
      this.zeroTime = new Date(new Date().toLocaleDateString()).getTime()
      await inquireRule2(this.examId).then((res) => {
        console.log('考试规则', res)
        if (res.success) {
          this.formList = res.data
          console.log('this.formList ', this.formList)
          // this.changTimeList()
        }
      })
    },
    // changTimeList () {
    //   this.listTime = []
    //   const interval = ((this.formList.examInterval + this.formList.examAnswerTime) * 60000)
    //   this.formList.examArrangementTimeSegmentList.forEach((val, i) => {
    //     let number = (val.examEndTimeDiff - val.examStartTimeDiff + (this.formList.examInterval * 60000)) / interval

    //     let startNum = this.formList.examArrangementTimeSegmentList[i].examStartTimeDiff + this.zeroTime
    //     for (let index = 0; index < number; index++) {
    //       this.listTime.push(startNum)
    //       startNum += interval
    //     }
    //   });
    //   console.log(this.listTime);
    // },
    // 时
  },
  filters: {
    arrangeModelFilters(key) {
      switch (key) {
        case 1:
          return `定时定点`
        case 2:
          return `随到随考`
        case 3:
          return `定点不定时`
        default:
          return `未选排考方式`
      }
    },
    timeFilters(val) {
      return dayjs(val).format('HH:mm')
    },
    arrangeAlgorithmFilters(key) {
      switch (key) {
        case 1:
          return `并排排考`
        case 2:
          return `阶梯排考`
        case 3:
          return `随到随考排考`
        default:
          return `未选排考模式`
      }
    },
    drawModelFilters(key) {
      switch (key) {
        case 1:
          return `分开模式`
        case 2:
          return `集中模式`
        default:
          return `未选抽签模式`
      }
    },
    randomTimingDrawTypeFilters(key) {
      switch (key) {
        case 1:
          return `报到即抽签`
        case 2:
          return `报到后抽签`
        default:
          return `未选抽签方式`
      }
    },
    randomTimingTypeFilters(key) {
      switch (key) {
        case 1:
          return `分开点名`
        case 2:
          return `集中点名`
        default:
          return `未选录入模式`
      }
    },
  },
}
</script>

<style lang="scss" scoped>
div > div {
  margin: 20px 0;
}
div > div span {
  display: inline-block;
}
</style>
