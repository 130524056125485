<template>
  <div>
    <!-- 表格 -->
    <el-table
      header-align="center"
      border
      :header-cell-style="{ background: '#DFE6EC' }"
      :data="tableData"
      stripe
      style="width: 100%"
    >
      <el-table-column
        align="center"
        prop="sysOrgExamPlaceName"
        label="考点名称"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="sysOrgExamPlaceRoomName"
        label="考场名称"
      ></el-table-column>
      <el-table-column
        align="center"
        type="expand"
        prop="examSeatDTOList"
        label="考试座位信息"
        width="110"
      >
        <template slot-scope="props">
          <!-- {{ props.row.examSeatDTOList }} -->
          <!-- {{ props.row.seatArrange[0].length }} -->
          <div
            class="seatList"
            v-for="(BoxList, i) in props.row.seatArrange"
            :key="i"
            :style="getJustifyContent(props.row.seatArrange, BoxList, i)"
          >
            <div
              v-for="(box, j) in BoxList"
              :key="j"
              :style="
                'width:' +
                100 /
                  Math.max(
                    ...props.row.seatArrange.map((item) => item.length)
                  ) +
                '%'
              "
              class="seatListBox"
            >
              <template v-for="(info, x) in props.row.examSeatDTOList">
                <template v-if="info.seatNum == box">
                  <div class="" :key="x" v-if="info.seatNum != 999">
                    <span>座位：{{ info.seatCode }}</span>
                    <span>{{ info.sysOrgOperationTestName }}</span>
                  </div>
                </template>
              </template>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="90">
        <template slot-scope="slot">
          <el-button
            v-throttle
            type="text"
            size="mini"
            @click="reviseBtn(slot.row, slot.$index)"
            >考题配置</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 修改考题弹窗 -->
    <el-dialog
      :title="`
      考点：${rowData.sysOrgExamPlaceName}\u00A0\u00A0\u00A0\u00A0\u00A0
      考场：${
        rowData.sysOrgExamPlaceRoomName
      }\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0
      科目：${
        rowData.subjectType == 1
          ? '物理'
          : rowData.subjectType == 2
          ? '生物'
          : '化学'
      }
      `"
      :visible.sync="dialogVisible"
      width="80%"
      :before-close="handleClose"
    >
      <div style="width: 100%; height: 600px; overflow-y: auto">
        <div
          class="seatList2"
          v-for="(BoxList, i) in rowData.seatArrange"
          :key="i"
          :style="getJustifyContent(rowData.seatArrange, BoxList, i)"
        >
          <div
            v-for="(box, j) in BoxList"
            :key="j"
            :style="
              'width:' +
              100 /
                Math.max(...rowData.seatArrange.map((item) => item.length)) +
              '%'
            "
            class="seatListBox"
          >
            <template v-for="(info, x) in rowData.examSeatDTOList">
              <template v-if="info.seatNum == box">
                <div class="" :key="x" v-if="info.seatNum != 999">
                  <span>座位：{{ info.seatCode }}</span>
                  <!-- <span>{{ info.sysOrgOperationTestName }}</span> -->

                  <span>
                    <el-select
                      v-model="info.sysOrgOperationTestId"
                      placeholder="请选择"
                      @change="
                        (value) => setSeatArrangeApiFunc(value, info.examSeatId)
                      "
                    >
                      <el-option
                        v-for="item in showDialogList"
                        :key="item.testQuestionId"
                        :label="item.operationTestName"
                        :value="item.testQuestionId"
                      >
                      </el-option>
                    </el-select>
                  </span>
                </div>
              </template>
            </template>
          </div>
        </div>
      </div>

      <span slot="footer" style="text-align: center">
        <el-button @click="A4Print">打 印</el-button>
        <el-button @click="saveSetSeatArrangeApiFunc">保 存</el-button>
        <el-tooltip
          class="item"
          effect="dark"
          content="将当前的考题座位安排应用到整个同科目的考场"
          placement="top-start"
        >
          <el-button type="primary" @click="applySameExamSubjectBtn"
            >应用到同科目</el-button
          >
        </el-tooltip>
      </span>
      <!-- 打印内容 tableShow-->
      <vue-easy-print ref="a4PrintContentRef">
        <!-- 标题 -->
        <div class="examA4Top">{{ examInformation.examName }}</div>
        <div class="examA4List">
          <div>
            当前考场：{{ rowData.sysOrgExamPlaceName }}&nbsp;&nbsp;&nbsp;&nbsp;
            {{ rowData.sysOrgExamPlaceRoomName }}
          </div>
          <div>考试时间：{{ examInformation.examStartTime | dayList }}</div>
          <div>考试科目：{{ rowData.subjectType | subjectFilter }}</div>
        </div>
        <div
          class="seatList2"
          v-for="(BoxList, i) in rowData.seatArrange"
          :key="i"
          :style="getJustifyContent(rowData.seatArrange, BoxList, i)"
        >
          <!-- -->
          <div
            class="seatListA4Box"
            v-for="(box, j) in BoxList"
            :key="j"
            :style="
              'width:' +
              100 /
                Math.max(...rowData.seatArrange.map((item) => item.length)) +
              '%'
            "
          >
            <template v-for="(info, x) in rowData.examSeatDTOList">
              <template v-if="info.seatNum == box">
                <div class="seatListA4list" :key="x" v-if="info.seatNum != 999">
                  <span>{{ info.seatCode }}</span>
                  <!-- <span>{{ info.sysOrgOperationTestName }}</span> -->
                  <template v-for="item in showDialogList">
                    <span
                      :key="item.testQuestionId"
                      v-if="info.sysOrgOperationTestId == item.testQuestionId"
                      >{{ item.operationTestName }}</span
                    >
                  </template>
                </div>
              </template>
            </template>
          </div>
        </div>
      </vue-easy-print>
    </el-dialog>
  </div>
</template>

<script>
import vueEasyPrint from 'vue-easy-print'
import dayjs from 'dayjs'
import {
  getSeatArrangeApi,
  setSeatArrangeApi,
  basicList2,
  basicList3,
  applySameExamSubject,
} from '@/api/exam/resultPreview.js'
export default {
  props: ['arrangeStatus'],
  components: { vueEasyPrint },
  props: ['arrangeStatus', 'rulesArrangeModel'],
  data() {
    return {
      examId: '',
      tableData: [],
      seatArrangeList: [],
      //弹框及内容
      dialogVisible: false,
      // 所有考题参数
      basicDataList: [],
      // 所有考题
      showDialogList: [],
      // 修改考题
      examSeatDTOList: [],
      // 编辑传入
      rowData: {},
      examInformation: {},
      // 保存修改座位考题
      changeExamSeatList: [],
    }
  },
  methods: {
    defaultReq() {
      if (this.arrangeStatus) {
        basicList3(this.examId).then((res) => {
          if (res.success) {
            this.examInformation = res.data
            console.log(
              'res.data.examPreviewSubjectInfoList',
              res.data.examPreviewSubjectInfoList
            )
            this.basicDataList = res.data.examPreviewSubjectInfoList.map(
              (e) => ({
                subject: e.subjectType,
                subjectTestList: e.itemDTOListList.map((evt) => ({
                  testQuestionId: evt.itemId,
                  operationTestName: evt.operationItemName,
                })),
              })
            )
            console.log('this.basicDataList', this.basicDataList)
          }
        })
      } else {
        basicList2(this.examId).then((res) => {
          if (res.success) {
            this.examInformation = res.data
            this.basicDataList = res.data.examArrangementSubjectAndTitleDTOList
          }
        })
      }
    },
    reqFunc(examId) {
      getSeatArrangeApi(examId)
        .then((res) => {
          // console.log('查询考试考场座位考题信息', res)
          if (res.success) {
            this.tableData = res.data
          }
        })
        .catch((err) => {})
    },
    // 点击修改按钮
    reviseBtn(row, i) {
      this.changeExamSeatList = []
      this.rowData = row
      // this.examSeatDTOList = row.examSeatDTOList
      this.basicDataList.forEach((val) => {
        if (val.subject == row.subjectType) {
          this.showDialogList = val.subjectTestList
          console.log('val', val)
        }
      })
      console.log('row', i, row)
      this.dialogVisible = true
    },
    // 修改座位考题
    setSeatArrangeApiFunc(sysOrgOperationTestId, examSeatId) {
      let change = false

      this.changeExamSeatList.forEach((val) => {
        if (val.examSeatId == examSeatId) {
          change = true
          val.sysOrgOperationTestId = sysOrgOperationTestId
        }
      })
      if (change) return
      let data = {
        sysOrgOperationTestId,
        examSeatId,
      }
      this.changeExamSeatList.push(data)
    },
    // 保存
    saveSetSeatArrangeApiFunc() {
      setSeatArrangeApi(this.examId, this.changeExamSeatList)
        .then((res) => {
          if (res.success) {
            this.$message.success('保存成功')
            this.changeExamSeatList = []
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((err) => {})
    },
    // 应用同科目
    applySameExamSubjectBtn() {
      applySameExamSubject(this.examId, this.rowData.examPlaceId)
        .then((res) => {
          if (res.success) {
            this.$message.success('应用成功')
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((err) => {})
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          this.reqFunc(this.examId)
          this.changeExamSeatList = []
          this.rowData = {}
          done()
        })
        .catch((_) => {})
    },
    // 打印
    A4Print() {
      this.$refs.a4PrintContentRef.print()
    },
    getJustifyContent(seatArrange, BoxList, i) {
      const isLastItem = seatArrange.length - 1 == i
      const isBoxListDiffOne =
        BoxList.length > 1 && BoxList[0] - BoxList[1] == 1
      const isSeatArrangeDiffOneAndOdd =
        seatArrange[1] &&
        seatArrange[1].length > 1 &&
        seatArrange[1][0] - seatArrange[1][1] == 1 &&
        !!(i % 2)

      return isLastItem && (isBoxListDiffOne || isSeatArrangeDiffOneAndOdd)
        ? 'justify-content: right'
        : ''
    },
  },
  mounted() {
    this.examId = this.$route.query.examId
    this.defaultReq(this.examId)
    this.reqFunc(this.examId)
  },
  filters: {
    dayList(val) {
      if (val) {
        return dayjs(val).format('YYYY-MM-DD HH:mm')
      } else {
        return `待排考`
      }
    },
    //学科过滤
    subjectFilter(val) {
      switch (val) {
        case 1:
          return `物理`
        case 2:
          return `生物`
        case 3:
          return `化学`
      }
    },
  },
  watch: {},
}
</script>

<style lang="scss" scoped>
::v-deep .el-table__expanded-cell {
  // padding: 0;
}
.seatList {
  display: flex;
  width: 100%;
  .seatListBox {
    display: inline-block;
    width: 25%;
    padding: 10px 20px;
    & > div {
      box-sizing: border-box;
      // text-align: center;
      width: 100%;
      border: 1px solid #ccc;
      border-radius: 8px;
      overflow: hidden;
    }
    span {
      display: block;
      padding: 10px 20px;
      font-size: 18px;
      &:first-child {
        background-color: #409eff;
        color: #fff;
      }
      &:last-child {
      }
    }
  }
}
.seatList2 {
  display: flex;
  width: 100%;
  // flex-wrap: wrap;
  // justify-content: center;
  .seatListBox {
    display: inline-block;
    width: 21%;
    // margin: 20px;
    box-sizing: border-box;
    padding: 10px 20px;
    span {
      display: block;
      padding: 10px 20px;
      font-size: 18px;
      &:first-child {
        background-color: #409eff;
        color: #fff;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
      }
      &:last-child {
        padding: 0;
      }
      ::v-deep .el-select {
        width: 100%;
        input {
          border-top: 0 !important;
          border-top-right-radius: 0;
          border-top-left-radius: 0;
          border-bottom-right-radius: 8px;
          border-bottom-left-radius: 8px;
        }
      }
    }
  }
  // 打印 列表
  .seatListA4Box {
    display: inline-block;
    width: 21%;
    // margin: 20px;
    box-sizing: border-box;
    padding: 5px 10px;
    .seatListA4list {
      padding: 10px 10px;
      background: #f2f2f2;
      border: 1px solid #f2f2f2;
    }
    span {
      display: block;
      font-size: 18px;
      text-align: center;
      &:last-child {
        font-size: 16px;
      }
    }
  }
}
.examA4Top {
  text-align: center;
  font-size: 26px;
  margin-bottom: 20px;
}
.examA4List {
  div {
    margin-bottom: 10px;
    font-size: 18px;
  }
}
</style>
