var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"header-align":"center","border":"","header-cell-style":{ background: '#DFE6EC' },"data":_vm.tableData,"stripe":""}},[_c('el-table-column',{attrs:{"align":"center","prop":"sysOrgExamPlaceName","label":"考点名称"}}),_c('el-table-column',{attrs:{"align":"center","prop":"sysOrgExamPlaceRoomName","label":"考场名称"}}),_c('el-table-column',{attrs:{"align":"center","type":"expand","prop":"examSeatDTOList","label":"考试座位信息","width":"110"},scopedSlots:_vm._u([{key:"default",fn:function(props){return _vm._l((props.row.seatArrange),function(BoxList,i){return _c('div',{key:i,staticClass:"seatList",style:(_vm.getJustifyContent(props.row.seatArrange, BoxList, i))},_vm._l((BoxList),function(box,j){return _c('div',{key:j,staticClass:"seatListBox",style:('width:' +
              100 /
                Math.max(
                  ...props.row.seatArrange.map((item) => item.length)
                ) +
              '%')},[_vm._l((props.row.examSeatDTOList),function(info,x){return [(info.seatNum == box)?[(info.seatNum != 999)?_c('div',{key:x},[_c('span',[_vm._v("座位："+_vm._s(info.seatCode))]),_c('span',[_vm._v(_vm._s(info.sysOrgOperationTestName))])]):_vm._e()]:_vm._e()]})],2)}),0)})}}])}),_c('el-table-column',{attrs:{"align":"center","label":"操作","width":"90"},scopedSlots:_vm._u([{key:"default",fn:function(slot){return [_c('el-button',{directives:[{name:"throttle",rawName:"v-throttle"}],attrs:{"type":"text","size":"mini"},on:{"click":function($event){return _vm.reviseBtn(slot.row, slot.$index)}}},[_vm._v("考题配置")])]}}])})],1),_c('el-dialog',{attrs:{"title":`
    考点：${_vm.rowData.sysOrgExamPlaceName}\u00A0\u00A0\u00A0\u00A0\u00A0
    考场：${
      _vm.rowData.sysOrgExamPlaceRoomName
    }\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0
    科目：${
      _vm.rowData.subjectType == 1
        ? '物理'
        : _vm.rowData.subjectType == 2
        ? '生物'
        : '化学'
    }
    `,"visible":_vm.dialogVisible,"width":"80%","before-close":_vm.handleClose},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('div',{staticStyle:{"width":"100%","height":"600px","overflow-y":"auto"}},_vm._l((_vm.rowData.seatArrange),function(BoxList,i){return _c('div',{key:i,staticClass:"seatList2",style:(_vm.getJustifyContent(_vm.rowData.seatArrange, BoxList, i))},_vm._l((BoxList),function(box,j){return _c('div',{key:j,staticClass:"seatListBox",style:('width:' +
            100 /
              Math.max(..._vm.rowData.seatArrange.map((item) => item.length)) +
            '%')},[_vm._l((_vm.rowData.examSeatDTOList),function(info,x){return [(info.seatNum == box)?[(info.seatNum != 999)?_c('div',{key:x},[_c('span',[_vm._v("座位："+_vm._s(info.seatCode))]),_c('span',[_c('el-select',{attrs:{"placeholder":"请选择"},on:{"change":(value) => _vm.setSeatArrangeApiFunc(value, info.examSeatId)},model:{value:(info.sysOrgOperationTestId),callback:function ($$v) {_vm.$set(info, "sysOrgOperationTestId", $$v)},expression:"info.sysOrgOperationTestId"}},_vm._l((_vm.showDialogList),function(item){return _c('el-option',{key:item.testQuestionId,attrs:{"label":item.operationTestName,"value":item.testQuestionId}})}),1)],1)]):_vm._e()]:_vm._e()]})],2)}),0)}),0),_c('span',{staticStyle:{"text-align":"center"},attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.A4Print}},[_vm._v("打 印")]),_c('el-button',{on:{"click":_vm.saveSetSeatArrangeApiFunc}},[_vm._v("保 存")]),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"将当前的考题座位安排应用到整个同科目的考场","placement":"top-start"}},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.applySameExamSubjectBtn}},[_vm._v("应用到同科目")])],1)],1),_c('vue-easy-print',{ref:"a4PrintContentRef"},[_c('div',{staticClass:"examA4Top"},[_vm._v(_vm._s(_vm.examInformation.examName))]),_c('div',{staticClass:"examA4List"},[_c('div',[_vm._v(" 当前考场："+_vm._s(_vm.rowData.sysOrgExamPlaceName)+"     "+_vm._s(_vm.rowData.sysOrgExamPlaceRoomName)+" ")]),_c('div',[_vm._v("考试时间："+_vm._s(_vm._f("dayList")(_vm.examInformation.examStartTime)))]),_c('div',[_vm._v("考试科目："+_vm._s(_vm._f("subjectFilter")(_vm.rowData.subjectType)))])]),_vm._l((_vm.rowData.seatArrange),function(BoxList,i){return _c('div',{key:i,staticClass:"seatList2",style:(_vm.getJustifyContent(_vm.rowData.seatArrange, BoxList, i))},_vm._l((BoxList),function(box,j){return _c('div',{key:j,staticClass:"seatListA4Box",style:('width:' +
            100 /
              Math.max(..._vm.rowData.seatArrange.map((item) => item.length)) +
            '%')},[_vm._l((_vm.rowData.examSeatDTOList),function(info,x){return [(info.seatNum == box)?[(info.seatNum != 999)?_c('div',{key:x,staticClass:"seatListA4list"},[_c('span',[_vm._v(_vm._s(info.seatCode))]),_vm._l((_vm.showDialogList),function(item){return [(info.sysOrgOperationTestId == item.testQuestionId)?_c('span',{key:item.testQuestionId},[_vm._v(_vm._s(item.operationTestName))]):_vm._e()]})],2):_vm._e()]:_vm._e()]})],2)}),0)})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }