<template>
  <div>
    <el-form v-if="showForm" :inline="true" :model="condition">
      <el-form-item label="所属地区" class="formItemBoxStyle">
        <el-select
          v-model="condition.orgAreaId"
          @change="changVal"
          placeholder="全部"
        >
          <el-option label="全部" :value="0"></el-option>
          <el-option
            :label="val.name"
            :value="val.sysOrgAreaId"
            v-for="val in this.conditionShow.sysOrgAreaList"
            :key="val.sysOrgAreaId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属考点" class="formItemBoxStyle">
        <el-select
          v-model="condition.orgExamPlaceId"
          @change="changVal"
          placeholder="全部"
        >
          <el-option label="全部" :value="0"></el-option>
          <el-option
            :label="val.name"
            :value="val.sysOrgExamPlaceId"
            v-for="val in this.conditionShow.sysOrgExamPlaceList"
            :key="val.sysOrgExamPlaceId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="科目" class="formItemBoxStyle">
        <el-select
          v-model="condition.subjectType"
          @change="changVal"
          placeholder="全部"
        >
          <el-option label="全部" :value="0"></el-option>
          <el-option
            :label="val | subjectTypeFilter"
            :value="val"
            v-for="val in this.conditionShow.subjectTypeList"
            :key="val"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-button v-throttle @click="changVal" type="primary">查询</el-button>
      <el-button v-throttle @click="emptyBtn">重置</el-button>
    </el-form>
    <!-- 表格 -->
    <el-table
      header-align="center"
      border
      :header-cell-style="{ background: '#DFE6EC' }"
      :data="tableData"
      stripe
      style="width: 100%"
    >
      <el-table-column
        align="center"
        prop="orgAreaName"
        label="所属地区"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="orgExamPlaceName"
        label="所属考点"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="orgExamPlaceRoomName"
        label="考场名称"
      ></el-table-column>
      <el-table-column align="center" label="考场科目">
        <template slot-scope="scope">{{
          scope.row.subjectType | subjectTypeFilter
        }}</template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="examExamineeNum"
        label="考场人次"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="examSessionCount"
        label="安排场次"
      ></el-table-column>
    </el-table>
    <el-pagination
      v-if="showForm"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="tableDataPagination.pageIndex"
      :page-sizes="[10, 20, 50, 100, 150, 200]"
      :page-size="tableDataPagination.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="tableDataPagination.total"
    ></el-pagination>
  </div>
</template>

<script>
import {
  placeCondition,
  placeList,
  arrangePlaceCondition,
  arrangePlaceList,
} from '@/api/exam/resultPreview.js'
export default {
  // props: ['arrangeStatus'],
  props: {
    arrangeStatus: {
      type: Boolean
    },
    showForm: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      // 分页
      tableDataPagination: {
        // 当前页
        pageIndex: 1,
        // 页大小
        pageSize: 10,
        // 页数
        pageTotal: 1,
        // 总数量
        total: 0,
      },
      examId: '',
      condition: {},
      conditionShow: {},
      tableData: [],
    }
  },
  filters: {
    subjectTypeFilter(key) {
      switch (key) {
        case 1:
          return `物理`
        case 2:
          return `生物`
        case 3:
          return `化学`
        default:
          return `没有改学科`
      }
    },
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.tableDataPagination.pageSize = val
      this.reqFunc()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.tableDataPagination.pageIndex = val
      this.reqFunc()
    },
    defaultCondition() {
      placeCondition(this.examId).then((res) => {
        console.log('考场安排条件', res)
        if (res.success) {
          this.conditionShow = res.data
          // 去重
          this.conditionShow.subjectTypeList = [
            ...new Set(res.data.subjectTypeList),
          ]
        }
      })
    },
    async defaultReq() {
      await placeList(
        this.examId,
        this.tableDataPagination.pageIndex,
        this.tableDataPagination.pageSize,
        this.condition
      ).then((res) => {
        console.log('场排考信息', res)
        if (res.success) {
          this.tableData = res.data
          this.tableData.forEach((val) => {
            val.examExamineeNum = '待排考'
            val.examSessionCount = '待排考'
          })

          this.tableDataPagination = {
            // 当前页
            pageIndex: res.pageIndex,
            // 页大小
            pageSize: res.pageSize,
            // 页数
            pageTotal: res.pageTotal,
            // 总数量
            total: res.total,
          }
          // this.$store.commit('set_venueNum', res.total)
          // this.tableDataPagination.total = res.total
          // this.tableDataPagination.pageIndex = res.pageIndex
          // this.tableDataPagination.pageSize = res.pageSize
          // this.tableDataPagination.pageTotal = res.pageTotal
        }
      })
    },
    emptyBtn() {
      this.condition = {}
      this.reqFunc()
    },
    // 条件处理
    changVal() {
      for (let key in this.condition) {
        if (
          this.condition[key] == 0 ||
          this.condition[key] == '' ||
          this.condition[key] == undefined
        ) {
          delete this.condition[key]
        }
      }
      this.tableDataPagination.pageIndex = 1
      this.reqFunc()
    },
    arrangeDefaultCondition() {
      arrangePlaceCondition(this.examId).then((res) => {
        console.log('派靠后的筛选信息', res)
        if (res.success) {
          this.conditionShow = res.data
          // 去重
          this.conditionShow.subjectTypeList = [
            ...new Set(res.data.subjectTypeList),
          ]
        }
      })
    },
    async arrangeDefaultReq() {
      await arrangePlaceList(
        this.examId,
        this.tableDataPagination.pageIndex,
        this.tableDataPagination.pageSize,
        this.condition
      ).then((res) => {
        console.log('派考试后场排考信息', res)
        if (res.success) {
          this.tableData = res.data
          this.tableDataPagination = {
            // 当前页
            pageIndex: res.pageIndex,
            // 页大小
            pageSize: res.pageSize,
            // 页数
            pageTotal: res.pageTotal,
            // 总数量
            total: res.total,
          }
          let num =
            this.tableData.reduce(
              (sum, e) => sum + e.examExamineeNum / e.examSessionCount,
              0
            ) / this.tableData.length
          num = isNaN(num) ? 1 : num
          this.$store.commit('set_seatNum', num)
        }
      })
    },
    // 请求
    async reqFunc() {
      if (this.arrangeStatus) {
        await this.arrangeDefaultReq()
      } else {
        await this.defaultReq()
      }
      this.$store.commit('set_venueNum', this.tableDataPagination.total)
    },
    // 条件
    conditionFunc() {
      if (this.arrangeStatus) {
        this.arrangeDefaultCondition()
      } else {
        this.defaultCondition()
      }
    },
  },
  async mounted() {
    this.examId = this.$route.query.examId
    await this.reqFunc()
    this.conditionFunc()
    // this.$store.commit('set_venueNum', this.tableDataPagination.total)
  },
}
</script>

<style lang="scss"></style>
